import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg``;

const Wrapper = styled.span``;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon viewBox="0 0 54 54" className={className}>
      <g fill="none" fillRule="evenodd">
        <g transform="">
          <path
            d="m1.6286484 48.8000266c-.82656805.4009773-1.28944616 1.2679554-1.14617437 2.1566079.1432718.8994897.83758896 1.5930721 1.75232427 1.7339561.67227534.1083722 1.39965523.1517211 2.13805602.1517211 2.00580513 0 5.83005998-.4009773 9.57716848-3.0235859 3.9785475 2.1566079 8.4750777 3.2945165 13.0487543 3.2945165 7.2186943 0 13.9965523-2.7634925 19.0992323-7.7811279 10.5359875-10.3603876 10.5359875-27.2014361 0-37.56182369-5.10268-5.0067982-11.880538-7.77029071-19.0992323-7.77029071s-13.9965523 2.76349251-19.09923244 7.78112793c-9.90779568 9.74266577-10.55802921 25.35910777-1.59803156 35.83870487-1.23434162 2.5359108-2.81033137 4.2807041-4.6728647 5.1801938zm7.81382329-39.50168709c4.68388561-4.60582085 10.92171911-7.15256885 17.55630541-7.15256885 6.6345862 0 12.8613988 2.53591077 17.5563053 7.15256885 9.6763567 9.51508399 9.6763567 25.00147929 0 34.52740059-4.6838856 4.6058208-10.9217191 7.1525688-17.5563053 7.1525688-4.4304048 0-8.7726423-1.1595831-12.5748553-3.3595399-.1763345-.097535-.3636899-.1517212-.5510454-.1517212-.2424599 0-.473899.0758606-.6722753.2275818-3.42750219 2.6117713-7.00929707 3.0019115-8.83876769 3.0019115-.52900355 0-1.04698619-.0325117-1.54292702-.0975351 2.38051598-1.2246065 4.30917476-3.4895866 5.75291362-6.729917.16531361-.3793029.09918817-.8236292-.18735542-1.1379087-8.66243316-9.6342935-8.19955505-24.3187341 1.0580071-33.43284079z"
            fill="#9eabbc"
          />
          <path
            d="m9.44247169 9.29833951c4.68388561-4.60582085 10.92171911-7.15256885 17.55630541-7.15256885 6.6345862 0 12.8613988 2.53591077 17.5563053 7.15256885 9.6763567 9.51508399 9.6763567 25.00147929 0 34.52740059-4.6838856 4.6058208-10.9217191 7.1525688-17.5563053 7.1525688-4.4304048 0-8.7726423-1.1595831-12.5748553-3.3595399-.1763345-.097535-.3636899-.1517212-.5510454-.1517212-.2424599 0-.473899.0758606-.6722753.2275818-3.42750219 2.6117713-7.00929707 3.0019115-8.83876769 3.0019115-.52900355 0-1.04698619-.0325117-1.54292702-.0975351 2.38051598-1.2246065 4.30917476-3.4895866 5.75291362-6.729917.16531361-.3793029.09918817-.8236292-.18735542-1.1379087-8.66243316-9.6342935-8.19955505-24.3187341 1.0580071-33.43284079z"
            fill="#fff"
          />
        </g>
        <ellipse
          cx="27"
          cy="26.372093"
          fill="#f27f95"
          fillOpacity=".268597"
          rx="20.571429"
          ry="20.093023"
        />
        <path
          d="m0 5.65927214c0-3.12595302 2.50182107-5.65927214 5.58610325-5.65927214 1.48879067 0 2.83949788.58881865 3.84123055 1.55025904 1.0017327-.96144039 2.3524399-1.55025904 3.8399753-1.55025904 3.0855374 0 5.5873585 2.53331912 5.5873585 5.65927214 0 3.12595301-8.4256011 12.14517956-9.4273338 11.18501086-1.00173267.9601687-9.4273338-8.05905785-9.4273338-11.18501086z"
          fill="#f0627d"
          transform="translate(18 18.837209)"
        />
      </g>
    </StyledIcon>
  </Wrapper>
);
