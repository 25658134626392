import React from "react";
import styled from 'styled-components';
import dayjs from 'dayjs';
import Img from 'gatsby-image';
import kebabCase from 'lodash/kebabCase';
import Link from '../../elements/Link';
import AngleLeft from '../Icons/AngleLeft';
import Content from '../Content';

const Title = styled.h1`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 42px;
  margin-left: -2.63px;
  line-height: 1.04;
  letter-spacing: -.015em;
  font-weight: 600;
  padding-top: 5px !important;
  color: ${props => props.theme.primary};
  color: rgba(0,0,0,.84);
  color: #656565;
  letter-spacing: 0;
  text-transform: capitalize;
  padding: 0 0 5px 0;
  margin: 10px 0 0;
`;

const ArticleDate = styled.div`
  font: 400 11px/23px ${props => props.theme.headerFontFamily};
  color: #656565;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  padding-top: 0;
`;

const TagLink = styled(Link)`
  text-decoration: none;
  margin-right: 8px;
  color: #656565;

  &:after {
    content: ' |';
  }

  &:last-child {
    &:after {
      content: '';
    }
  }
`;

const TagName = styled.span`
  font: 400 11px/23px ${props => props.theme.headerFontFamily};
  color: #656565;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  padding-top: 0;
  position: relative;
  transition: color 0.2s ease;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const StyledImage = styled(Img)`
  margin-top: 10px;
  margin-bottom: 10px;

  > img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
`;

const StyledLink = styled(Link)`
  font: 300 14px/1.58 ${props => props.theme.headerFontFamily};
  text-rendering: optimizeLegibility;
  color: #656565;
  letter-spacing: -0.003em;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.2s ease;
  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const StyledAngle = styled(AngleLeft)`
  margin-top: 1px;
  position: absolute;
  height: 19px;
  width: 19px;
`;

const LinkText = styled.span`
  display: inline-block;
  margin-left: 18px;
`;

export default ({ article, pageNumber }) => {
  let tags = [];
  if (article.tags !== null) {
    tags = article.tags
      .map(tag => {
        return <TagLink key={tag.name} to={`/tags/${kebabCase(tag.name)}`}>
            <TagName>{tag.name}</TagName>
          </TagLink>;
      })
  }
  const articlesPage = pageNumber > 1 ? `/articles/${pageNumber}` : `/articles`;
  return <>
      <StyledLink to={articlesPage}>
        <StyledAngle /> <LinkText>Back to articles</LinkText>
      </StyledLink>
      <Title>{article.title}</Title>
      <ArticleDate>
        {`${dayjs(article.publishDate).format('DD MMMM YYYY')}`}
        <br />
      {`Written by ${article.author !== null ? article.author.name : 'Nubabi'}${(article.author !== null && article.author.discipline !== null) ? ' | ' + article.author.discipline.name : ''}`}
        <br />
        Tags: {tags}
      </ArticleDate>
      <StyledImage key={article.featuredImage.title} alt={article.featuredImage.title} fluid={article.featuredImage.fluid} />
      <Content itemscope itemtype="http://schema.org/articleBody" itemprop="mainEntityOfPage" content={article.body !== null ? article.body.childMarkdownRemark.html : ''} />
    </>;
}
