import React from 'react';
import styled from 'styled-components';
import Icon from '../Icons/SignupHeart';
import Logo from '../Icons/Logo';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

const Wrapper = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const Headers = styled.div`
  border-left: 1px solid rgba(140, 140, 140, 0.3);
  border-right: 1px solid rgba(140, 140, 140, 0.3);
  padding: 15px 10px 10px;
  margin: 0;
`;

const Heading = styled.p`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 25px;
  line-height: 32px;
  color: #4f4f51;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  margin-top: 0;
`;

const SubHeading = styled.p`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 15px;
  line-height: 21px;
  color: #4f4f51;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
`;

const SignupButton = styled.a`
  background-color: ${props => props.theme.primary};
  color: #fff;
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 15px;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100%;
  padding: 15px;
  display: inline-block;
  border: 1px solid ${props => props.theme.primary};
  box-sizing: border-box;
  transition: background-color 0.1s 0.1s, color 0.1s 0.1s,
    border-color 0.1s 0.1s;

  &:hover {
    border-color: #00a0e1;
    background-color: #00a0e1;
    cursor: pointer;
  }
`;

const StyledIcon = styled(Icon)`
  height: 54px;
  width: 54px;
  position: absolute;
  margin-top: -60px;
  margin-left: 40px;
`;

const StyledImage = styled(Img)`
  width: 100%;
  margin: 0;
  padding: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  > img {
    width: 100%;
  }
`;

const StyledLogo = styled(Logo)`
  width: 77px;
  position: absolute;
  margin-top: 15px;
  margin-left: 15px;
`;

export default () => (
  <StaticQuery
    query={graphql`
      query {
        adImage: contentfulAsset(
          id: { eq: "7700db58-a8a5-5b5d-a476-fbe0cfdc9723" }
        ) {
          title
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <StyledLogo />
        <StyledImage alt={data.adImage.title} fluid={data.adImage.fluid} />
        <Headers>
          <StyledIcon />
          <Heading>Have you tried the Nubabi Free Trial?</Heading>
          <SubHeading>
            Get unlimited access to <strong>Your Parenting Toolkit</strong> for
            2 weeks for free!
            <br />
            <br />
            Track, Boost, Explore and Capture your child's growth and
            development.
            <br />
            Available on both mobile and web.
          </SubHeading>
        </Headers>
        <SignupButton href={process.env.GATSBY_SIGNUP_URL}>
          Sign me up!
        </SignupButton>
      </Wrapper>
    )}
  />
);

