import React from 'react';
import Article from '../Article';
import Section from '../../elements/Section';
import Wrapper from '../../elements/Wrapper';
import styled from 'styled-components';
import { media } from '../../utils/styles';
import ShareButtons from '../ShareButtons';
import Sidebar from '../Sidebar';
import getSections from '../../utils/sections';

const LeftColumn = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;

  ${media.tablet`
    width: 66.66666667%;
  `} ${media.smallDesktop`
    width: 66.66666667%;
  `};
`;

const RightColumn = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 40px;
  float: left;

  &::after {
    clear: both;
  }

  ${media.tablet`
    width: 33%;
  `} ${media.smallDesktop`
    width: 25%;
    margin-left: 8.33333333%;
  `};
`;

const SectionWrapper = styled.div`
  margin-bottom: 30px;
`;

export default ({ url, pageNumber, article }) => {
  const imageUrl = `https:${article.featuredImage.fluid.src}`;
  const description =
    article.description !== null ? article.description.description : '';
  const sections = article.footerSections &&
    article.footerSections.length > 0 &&
    getSections(article.footerSections);
  return <Section>
      <Wrapper header={true}>
        <LeftColumn>
          <Article article={article} pageNumber={pageNumber} />
          <ShareButtons url={url} title={article.title} image={imageUrl} description={description} />
          {sections && <SectionWrapper>{sections}</SectionWrapper>}
        </LeftColumn>
        <RightColumn>
          <Sidebar relatedPosts={article.relatedPosts} />
        </RightColumn>
      </Wrapper>
    </Section>;
};