import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  width: 20px;
  height: 20px;
`;

const Wrapper = styled.span`
  padding: 0;
  margin: 0;
`;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon viewBox="0 0 1792 1792" className={className}>
      <path
        fill="#656565"
        d="m1203 544q0 13-10 23l-393 393 393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23z"
      />
    </StyledIcon>
  </Wrapper>
);
