import React from 'react';
import styled from 'styled-components';
import { SignupButton } from '../../elements/Buttons';
import Icon from '../Icons/Newsletter';
import { compose, lifecycle, withState, withHandlers } from 'recompose';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const StyledIcon = styled(Icon)`
  width: 100px;
  height: 82;
`;

const StyledInput = styled.input`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 18px;
  line-height: 44px;
  font-weight: 300;
  letter-spacing: 0;
  color: #8C8C8C;
  display: block;
  width: 100%;
  height: 44px;
  margin: 0 0 20px;
  padding: 0 18px;
  border: ${props => props.error ? `1px solid ${props.theme.primary}` : '1px solid #efefef'};
  border-radius: 10px;
  outline: 0;
  background: transparent;
  margin-top: 20px;
  box-sizing: border-box;

  &:invalid {
    border: 1px solid ${props => props.theme.primary};
  }
`;

const Form = styled.form`
  border: 1px solid rgba(140, 140, 140, 0.3);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
`;

const Heading = styled.p`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 16px;
  line-height: 22px;
  color: #4F4F51;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
`;

const SubHeading = styled.p`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 16px;
  line-height: 22px;
  color: #4F4F51;
  font-weight: 400;
`;

const ThankYou = styled.p`
  color: ${props => props.theme.primary};
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
`;

const enhance = compose(
  withState('email', 'setEmail', ''),
  withState('formErrors', 'setFormErrors', { email: '' }),
  withState('emailChanged', 'setEmailChanged', false),
  withState('emailValid', 'setEmailValid', false),
  withState('formErrors', 'setFormErrors', { email: '' }),
  withState('formValid', 'setFormValid', false),
  withState('message', 'setMessage', null),
  withHandlers({
    validateForm: ({ emailValid, setFormValid }) => args => {
      setFormValid(emailValid);
    },
  }),
  withHandlers({
    validateField: ({
      formErrors,
      emailValid,
      validateForm,
      setFormErrors,
      setEmailValid
    }) => ({ fieldName, value }) => {
      let fieldValidationErrors = formErrors;
      switch (fieldName) {
        case 'email':
          emailValid = value.match(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i
          );
          fieldValidationErrors.email = emailValid
            ? ''
            : ' is invalid email address';
          break;
        default:
          break;
      }
      setFormErrors(fieldValidationErrors);
      setEmailValid(emailValid);
      validateForm();
    }
  }),
  withHandlers({
    handleChange: props => args => {
      const { setEmail, setEmailChanged, validateField } = props;
      const name = args.target.name;
      const value = args.target.value;
      setEmail(value);
      setEmailChanged(true);
      validateField({ fieldName: name, value });
    }
  }),
  withHandlers({
    handleSubmit: props => e => {
      if (props.formValid) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'newsletter', 'email': props.email })
        })
          .then(() => {
            props.setMessage('Thank you for subscribing :)');
            props.setEmail('');
            props.setEmailChanged(false);
            props.setFormValid(false);
            props.setEmailValid(false);
          })
          .catch(error => alert(error));
      } else {
        props.setEmailChanged(true);
        props.setMessage('');
      }
      e.preventDefault();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.validateForm();
    }
  })
);

export default enhance(({
  handleSubmit,
  email,
  handleChange,
  emailValid,
  emailChanged,
  formValid,
  message,
}) => (
  <>
    <Form
      name="newsletter"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}>
      <StyledIcon />
      <Heading>SUBSCRIBE TO OUR NUBABI NEWSLETTER</Heading>
      <SubHeading>Join 10,000 other parents and receive regular articles written by our experts directly into your inbox.</SubHeading>
      <StyledInput
        type="email"
        name="email"
        placeholder="Your Email Address"
        value={email}
        onChange={handleChange}
        aria-required="true"
        aria-label="Email Address"
        error={!emailValid && emailChanged}
      />
      <SignupButton
        type="submit"
        name="signup"
        aria-label="Subscribe to our newsletter"
        value="Subscribe"
        disabled={!formValid && emailChanged}
      />
    </Form>
    {message && <ThankYou>{message}</ThankYou>}
  </>
));
