import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';

const StyledIcon = styled.svg``;

const Wrapper = styled.span``;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon viewBox="0 0 110 84" className={className}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-1023 -484)">
          <g transform="translate(954 469)">
            <g transform="translate(69 16)">
              <ellipse
                cx="58"
                cy="40.821"
                rx="41"
                ry="40.821"
                fill="#fff"
                stroke="#EDF0F9"
              />
              <ellipse
                cx="104.59"
                cy="22.579"
                rx="3.5866"
                ry="3.5789"
                stroke={theme.primary}
                strokeWidth="2"
              />
              <path
                d="m87.453 16.547c-3.1471-0.25272-5.4528-1.0736-5.4528-2.0472s2.3057-1.7945 5.4528-2.0472c0.25272-3.1471 1.0736-5.4528 2.0472-5.4528s1.7945 2.3057 2.0472 5.4528c3.1471 0.25272 5.4528 1.0736 5.4528 2.0472s-2.3057 1.7945-5.4528 2.0472c-0.25272 3.1471-1.0736 5.4528-2.0472 5.4528s-1.7945-2.3057-2.0472-5.4528z"
                fill="#9EABBC"
              />
              <ellipse
                cx="4.4706"
                cy="35.463"
                rx="3.4706"
                ry="3.4632"
                stroke="#9EABBC"
                strokeWidth="2"
              />
              <g transform="translate(9 40)" fill={theme.primary}>
                <path d="m5.8425 9.3034c-3.0816-0.24274-5.3413-1.0378-5.3413-1.9811s2.2597-1.7383 5.3413-1.9811c0.24274-3.0816 1.0378-5.3413 1.9811-5.3413s1.7383 2.2597 1.9811 5.3413c3.0816 0.24274 5.3413 1.0378 5.3413 1.9811s-2.2597 1.7383-5.3413 1.9811c-0.24274 3.0816-1.0378 5.3413-1.9811 5.3413s-1.7383-2.2597-1.9811-5.3413z" />
              </g>
              <g transform="translate(33 16)">
                <path
                  d="m11 7h27v27.264c-2.6151 1.5988-6.9508 0.59139-13.007-3.0221-1.2991-0.77511-2.4412 0.61566-3.5547 1.2788-1.5659 0.9326-3.0188 1.4798-4.2794 1.7433-1.348 0.28181-2.9743 0.0076187-4.8791-0.82258l-1.2799-26.442z"
                  fill="#FCDDE3"
                  opacity=".52933"
                />
                <g fillRule="nonzero">
                  <path
                    d="m50.503 15.508l-10.103-6.5713c-0.079011-0.050749-0.16614-0.069978-0.25109-0.098347v-2.2664c0-0.60521-0.48531-1.0954-1.0852-1.0954h-4.0293l-8.4164-5.3095c-0.35165-0.22254-0.79886-0.22254-1.1508 0l-8.4164 5.3095h-5.1145c-0.59961 0-1.0852 0.48984-1.0852 1.0954v2.2658c-0.084945 0.028369-0.17239 0.047913-0.25109 0.098977l-10.103 6.5713c-0.30949 0.20205-0.49718 0.54879-0.49718 0.92074v28.476c0 0.60521 0.48531 1.0954 1.0852 1.0954h48.83c0.59961 0 1.0852-0.48984 1.0852-1.0954v-28.476c0-0.37227-0.18769-0.71869-0.49718-0.92106zm-24.46-13.12l4.8971 3.0888h-9.7943l4.8971-3.0888zm-13.021 5.2792h24.957v24.643c0 0.0031522 0.0018738 0.0056739 0.0018738 0.0091413l-3.6707 2.2497-8.833-4.8628c-0.32947-0.17967-0.73015-0.17873-1.0574 0.010717l-8.0604 4.6491-3.3391-2.0464c0-0.0031522 0.0018738-0.0056739 0.0018738-0.008826l-3.123e-4 -24.643zm-10.851 9.3587l8.6806-5.6455v19.61l-8.6806-5.3202v-8.6444zm0 11.206l12.035 7.3751-12.035 6.9416v-14.317zm2.1867 15.578l20.613-11.888 21.594 11.888h-42.207zm44.473-1.2479l-12.318-6.7815 12.318-7.5491v14.331zm0-16.892l-8.6809 5.3199v-19.61l8.6809 5.6458v8.6441z"
                    fill="#9EABBC"
                  />
                  <path
                    d="m17.834 18.4h13.049c0.60088 0 1.0875-0.47147 1.0875-1.0543v-6.3248c0-0.58251-0.48634-1.0543-1.0875-1.0543h-13.049c-0.60088 0-1.0875 0.47147-1.0875 1.0543v6.3248c0 0.58281 0.48665 1.0543 1.0875 1.0543zm1.0872-6.3251h10.874v4.2165h-10.874v-4.2165z"
                    fill={theme.primary}
                  />
                  <rect
                    x="16.746"
                    y="20.7"
                    width="17.507"
                    height="2.3"
                    fill={theme.primary}
                  />
                  <rect
                    x="16.746"
                    y="25.3"
                    width="17.507"
                    height="2.3"
                    fill={theme.primary}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </StyledIcon>
  </Wrapper>
);
