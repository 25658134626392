import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ArticleTemplate from '../components/ArticleTemplate';
import Seo from '../components/Seo';

export default ({
  data: { contentfulBlogPost: article },
  location: { href },
  pageContext: { pageNumber }
}) => (
  <Layout canonicalUrl={href}>
    <Seo
      schema={'Article'}
      contentType={'article'}
      title={article.title}
      url={href}
      description={
        article.description !== null ? article.description.description : ''
      }
      author={article.author !== null ? article.author.name : 'Nubabi'}
      publishedAt={article.publishDate}
      imageSrc={`https:${article.featuredImage.fluid.src}`}
      tags={article.tags}
    />
    <ArticleTemplate url={href} pageNumber={pageNumber} article={article} />
  </Layout>
);

export const articleQuery = graphql`
  query ArticleQuery($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      id
      title
      description {
        id
        description
      }
      publishDate
      slug
      tags {
        name
      }
      body {
        id
        body
        childMarkdownRemark {
          html
        }
      }
      featuredImage {
        title
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
      author {
        id
        name
        discipline {
          id
          name
        }
        biography {
          id
          biography
        }
      }
      tags {
        id
        name
      }
      footerSections {
        ... on ContentfulSectionImage {
          id
          url
          imageSource {
            title
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulSectionContent {
          id
          content {
            childMarkdownRemark {
              html
            }
          }
          css {
            css
          }
        } 
      }
      relatedPosts {
        slug
        title
        author {
          name
        }
        publishDate
        featuredImage {
          fluid(maxWidth: 150) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
