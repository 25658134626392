import React from 'react';
import RelatedPosts from './RelatedPosts';
import Newsletter from './Newsletter';
import SignupAd from './SignupAd';

export default ({ relatedPosts }) => {
  return (
    <>
      {relatedPosts && <RelatedPosts relatedPosts={relatedPosts} />}
      <SignupAd />
      <Newsletter />
    </>
  );
}