import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const Title = styled.h5`
  font-family: ${props => props.theme.headerFontFamily};
  font-weight: 600;
  color: #4F4F51;
  letter-spacing: 0;
  text-transform: none;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Separator = styled.div`
  background-color: #5d5d5d;
  height: 1px;
  width: 30px;
  margin: 5px 0;
`;

const PostsList = styled.ul`
  list-style: none outside none;
  padding: 0;
  margin-top: 10px;
`;

const PostPreview = styled.li`
  padding: 5px 0;
  display: inline-block;
  width: 100%;
`;

const PostTitle = styled(Link)`
  font-family: ${props => props.theme.headerFontFamily};
  font-weight: 300;
  color: #4f4f51;
  letter-spacing: 0;
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
  line-height: 16px;
  text-decoration: none;
  margin-top: -4px;
  transition: color 0.2s ease;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const StyledImage = styled(Img)`
  height: 48px;
  width: 75px;
  margin-right: 20px;
  float: left;
`;

export default ({ relatedPosts }) => {
  return (
    relatedPosts == null ? null :
      <>
        <Title>Related Posts</Title>
        <Separator />
        <PostsList>
          {relatedPosts.map(post =>
            (post.featuredImage &&
              <PostPreview key={post.slug}>
                <StyledImage
                  key={post.featuredImage.src}
                  alt={post.featuredImage.title}
                  fluid={post.featuredImage.fluid}
                />
                <PostTitle to={`/articles/${post.slug}`}>
                  {post.title}
                </PostTitle>
              </PostPreview>
            )
          )}
        </PostsList>
      </>
  );
}