import styled from 'styled-components';

export default styled.section`
  padding: 0;
  margin: 0;
  position: relative;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#fff'};

  &:after {
    content: '';
    clear: both;
    display: block;
  }
`;
